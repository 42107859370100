import { useMemo, useRef, useState } from 'react';

import { useOutsideClick } from '../../../hooks/useOutsideClick';
import { type PairingRound } from '../../../types';
import { type Option } from '../../common/Utilities';
import { ArrowDownIcon, ArrowUpIcon } from '../../icons/Arrows';
import { useRounds } from './CRUD';

type FilterOption = Option<PairingRound | null>;

const OptionAll: FilterOption = {
  label: 'All Orgs',
  value: null,
};

export const PairingRoundsFilter = (props: {
  globalRoundId: string;
  onSelect: (round: PairingRound | null) => void;
}): JSX.Element => {
  const { globalRoundId, onSelect } = props;

  const { rounds } = useRounds({
    globalRoundId,
    orderBy: 'orgName',
  });
  const options = useMemo(() => {
    const res = [OptionAll];
    if (rounds) {
      res.push(
        ...rounds.map((r) => ({
          label: r.organization?.name ?? '',
          value: r,
        }))
      );
    }

    return res;
  }, [rounds]);

  const [selectedOption, setSelectedOption] = useState<FilterOption>(
    options[0]
  );

  const [active, setActive] = useState(false);

  const ref = useRef<HTMLDivElement>(null);
  useOutsideClick(ref, () => {
    setActive(false);
  });

  const handleSelect = (option: FilterOption) => {
    setSelectedOption(option);
    setActive(false);
    onSelect(option.value);
  };

  return (
    <div className={`relative text-base text-white`} ref={ref}>
      <button
        type='button'
        className='btn-secondary text-white flex items-center justify-center h-10 w-40'
        onClick={() => setActive(!active)}
      >
        <p className='truncate px-1 w-30'>{selectedOption?.label}</p>
        {active ? <ArrowUpIcon /> : <ArrowDownIcon />}
      </button>
      {active && (
        <div
          className='absolute flex flex-col border border-secondary rounded-xl
        px-6 py-3 mt-px bg-black whitespace-nowrap z-5 w-40'
        >
          {options.map((op) => (
            <div
              key={op.value?.id ?? ''}
              className='py-1.5 truncate cursor-pointer text-sms w-full'
              onClick={() => handleSelect(op)}
            >
              {op.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
