import { PairingMessageList } from '../components/Pairing/Message/List';

function GlobalPairingRoundMessagesRoute() {
  return <PairingMessageList />;
}

export const Component = GlobalPairingRoundMessagesRoute;

export function clientLoader() {
  return null;
}
